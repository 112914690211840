import React from "react"
import dynamic from "next/dynamic"
import Image from "next/image"
import { uid } from "uid"

const TitleUnderlined = dynamic(() => import("../title/title-underlined"), {
  ssr: true,
})
const TextContent = dynamic(() => import("../contentful/text-content"), {
  ssr: true,
})
const Shapes = dynamic(() => import("../bg-pattern/shapes"), { ssr: false })
const ButtonPlayVideo = dynamic(() => import("../buttons/button-play-video"), {
  ssr: false,
})

function BlocksColumnContentCustom({
  classList,
  title,
  titleTag,
  titleClassList,
  lineWidth,
  titleAlign,
  content,
  items,
  columnsClassList,
  containerClassList,
  shape,
  shapeClassList,
  contentAlign,
  itemsWrapperClassList,
  descriptionClassList,
  subtitleClassList,
  fontWeightContent,
  contentClasses,
  fontWeightColumns,
}) {
  return (
    <article
      className={`py-10 relative ${classList}`}
      data-layer-component="blocks-column-content-custom"
    >
      {shape ? <Shapes classList={`absolute ${shapeClassList}`} /> : null}
      <div className="container lg:max-w-xl">
        {title && (
          <div
            className={`-ml-15 flex items-stretch flex-wrap text-center -mt-2 mb-8 ${descriptionClassList}`}
          >
            <div className="pl-15 w-full mt-2">
              <TitleUnderlined
                tag={titleTag}
                classList={titleClassList}
                content={title}
                colorTitle="text-black"
                lineWidth={lineWidth}
                titleAlign={titleAlign}
              />
            </div>
            {content && (
              <div className="pl-15 w-full mt-2">
                <TextContent
                  classes={contentClasses}
                  contentAlign={contentAlign}
                  fontWeight={fontWeightContent}
                  content={content}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={`container ${containerClassList}`}>
        {items.length > 0 && (
          <div
            className={`-ml-5 md:-ml-15 -mt-15 lg:-mt-30 flex items-stretch ${itemsWrapperClassList} flex-wrap`}
          >
            {items.map(item => (
              <div
                className={`pl-5 md:pl-15 ${columnsClassList} mt-15 lg:mt-30`}
                key={uid(16)}
              >
                {item.imageIcon && (
                  <div className="w-full relative mx-auto max-w-135px mb-8">
                    <Image
                      src={item.imageIcon.url}
                      alt={item.imageIcon.title}
                      layout="responsive"
                      sizes="10vw"
                      width={80}
                      height={80}
                      className="object-contain"
                      loading="lazy"
                      placeholder="blur"
                      blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                    />
                    {item.videoId && item.source ? (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <ButtonPlayVideo
                          id={item.videoId}
                          source={item.source}
                          buttonClassList="w-5 h-5 text-white"
                        />
                      </div>
                    ) : null}
                  </div>
                )}
                {item.name && item.imageIcon ? (
                  <h5
                    className={`mb-0 font-noto ${subtitleClassList}`}
                  >
                    {item.name}
                  </h5>
                ) : (
                  <h4 className={`mb-2 text-lg ${subtitleClassList}`}>
                    {item.name}
                  </h4>
                )}
                {item.subtitle && (
                  <p className="-mt-3 md:mt-0 mb-2 text-body font-noto">
                    {item.subtitle}
                  </p>
                )}
                {item.description && (
                  <TextContent
                    classes={fontWeightColumns}
                    content={item.description}
                    contentAlign={contentAlign}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </article>
  )
}

export default BlocksColumnContentCustom
